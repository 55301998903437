<template>
	<div class="view pa24">
		<p class="cA2 fwbold fs8 mb20">1.选择产品分类</p>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
			<el-form-item label="产品分类" prop="productsTypeId">
				<el-select v-model="ruleForm.productsTypeId" placeholder="请选择" clearable>
					<el-option v-for="(item, index) in options" :key="index" :label="item.productsTypeName"
						:value="item.productsTypeId">
						{{ item.productsTypeName }}
					</el-option>
				</el-select>
				<el-button class="ml10" type="primary" @click="addGoodsType">新增分类</el-button>
			</el-form-item>
			<p class="cA2 fwbold fs8 mb20">2.输入产品基本信息</p>
			<el-form-item label="产品标题" prop="productsName">
				<el-input type="text" placeholder="请输入内容" style="width: 330px" v-model="ruleForm.productsName"
					maxlength="30" show-word-limit />
			</el-form-item>
			<el-form-item label="产品价格" prop="specificPrice">
				<el-radio v-model="radio" label="1">
					具体金额
					<el-input style="width: 100px" type="number" :disabled="radio !== '1'"
						v-model="ruleForm.specificPrice" placeholder="如：99.00"></el-input>
				</el-radio>
				<el-radio v-model="radio" label="2">
					区间金额
					<el-input style="width: 100px" type="number" :disabled="radio !== '2'"
						v-model="ruleForm.areaPrice[0]" placeholder="如：99"></el-input>
					~
					<el-input style="width: 100px" type="number" :disabled="radio !== '2'"
						v-model="ruleForm.areaPrice[1]" placeholder="如：99"></el-input>
				</el-radio>
			</el-form-item>
			<el-form-item label="产品简述" prop="describe">
				<el-input style="width: 330px" v-model="ruleForm.describe" placeholder="请输入内容"></el-input>
			</el-form-item>
			<el-form-item label="商品图片" prop="productsPhoto">
				<p style="color: rgb(150, 150, 150)">
					上传图片的最佳尺寸：800像素*800像素，其他尺寸会影响页效果，格式png，jpeg，jpg。大小不超过2M，商品图片一共可以上传5张，默认第一张为主图封面
				</p>
				<el-upload
				  :action="uploadUrl"
					:headers="upParams"
					:class="{ disabled: uploadDisabled }" list-type="picture-card" :on-progress="handProgress"
					:on-success="handleSuccess" :on-remove="handleRemove" :limit="5"
					:file-list="ruleForm.productsPhoto"
				>
					<i class="el-icon-plus"></i>
				</el-upload>
			</el-form-item>
			<el-form-item label="分享图片" prop="sharePhoto">
				<p style="color: rgb(150, 150, 150)">
					上传图片及支持png/jpg 。显示图片长宽比为5:4
				</p>
				<el-upload
				  :action="uploadUrl" 
				  :headers="upParams"
					list-type="picture-card" :class="{ disabled: uploadShareDisabled }" :file-list="ruleForm.sharePhoto"
					:on-progress="handProgress" :on-success="handleSuccess2" :on-remove="handleRemove2" :limit="1">
					<i class="el-icon-plus"></i>
					<div style="color: #bcc3d3" class="el-upload__tip" slot="tip">
						用于小程序分享时展示图片
					</div>
				</el-upload>
			</el-form-item>
			<p class="cA2 fwbold fs8 mb20">3.服务方式</p>
			<el-form-item label="服务方式" prop="serviceType">
				<el-checkbox-group v-model="ruleForm.serviceType">
					<el-checkbox label="1">到店</el-checkbox>
					<el-checkbox label="2">上门</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<p class="cA2 fwbold fs8 mb20">4.输入产品详情</p>
			<quill-editor style="height: 100%" v-model="ruleForm.productsDetails" ref="myQuillEditor"
				:options="editorOption" />
		</el-form>
		<el-button class="mt10" type="primary" @click="addGoodsInfo">发布</el-button>
	</div>
</template>

<script>
	import {
		getProductsInfo,
		selectProductsTypeList,
		insertProducts,
	} from "@/api/management";
	import {
		addAppointmentGoodsType,
	} from "@/api/companyManage.js";
	import {
		quillEditor,
		Quill
	} from "vue-quill-editor"; //
	import resizeImage from "quill-image-resize-module"; // 调整大小组件。
	import {
		ImageDrop
	} from "quill-image-drop-module"; // 拖动加载图片组件
	import quillConfig from "@/utils/quill-config.js";
	Quill.register("modules/imageDrop", ImageDrop);
	Quill.register("modules/resizeImage ", resizeImage);
	import "quill/dist/quill.core.css";
	import "quill/dist/quill.snow.css";
	import "quill/dist/quill.bubble.css";
	quillConfig.placeholder = '请填写商品内容'
	export default {
		data() {
			let token = localStorage.getItem("token")
			var validatePass2 = (rule, value, callback) => {
				if (this.radio === "1" && this.ruleForm.specificPrice == "") {
					console.log(2222)
					callback(new Error("请输入具体金额"));
				} else if (
					this.radio === "2" &&
					this.ruleForm.areaPrice[0] == "" &&
					this.ruleForm.areaPrice[1] == ""
				) {
					callback(new Error("请输入区间金额!"));
				} else {
					callback();
				}
			};
			return {
				uploadUrl: this.$store.state.uploadingUrl,
				upParams: {
					token: token
				},
				ossUrl: this.$store.state.ossUrl,
				editorOption: quillConfig,
				radio: "1",
				ruleForm: {
					productsTypeId: "",
					productsName: "",
					specificPrice: "",
					areaPrice: ["", ""],
					describe: "",
					serviceType: [],
					productsPhoto: [],
					sharePhoto: [],
					productsDetails: "",
				},
				Details: '',
				rules: {
					productsTypeId: [{
						required: true,
						message: "请选择分类！"
					}],
					productsName: [{
						required: true,
						message: "请输入产品标题！",
						trigger: "change",
					}, ],
					specificPrice: [{
						validator: validatePass2
					}],
					describe: [{
						required: true,
						message: "请输入描述",
						trigger: "change"
					}, ],
					productsPhoto: [{
						required: true,
						message: "请上传商品图片"
					}],
					sharePhoto: [{
						required: true,
						message: "请上传分享图片"
					}],
					serviceType: [{
						required: true,
						message: "请选择服务方式！"
					}],
				},
				options: [],
			};
		},
		components: {
			quillEditor,
		},
		computed: {
			uploadDisabled() {
				return this.ruleForm.productsPhoto.length >= 5;

			},
			uploadShareDisabled() {
				return this.ruleForm.sharePhoto.length !== 0;
			}
		},
		async created() {
			this.selectProductsTypeList();
			if (this.$route.query.id) {
				this.getProductsInfo();
			}
		},
		methods: {
			beforeAvatarUpload(file) { //限制图片上传大小
				// const isLt2M = file.size / 1024 / 1024 < 2;
				// if (!isLt2M) {
				// 	this.$message.error('上传图片大小不能超过 2MB!');
				// }
				// return isLt2M;
			},
			handProgress(event, file, fileList) {
				this.$message({
					message: "正在上传",
				});
			},
			handleRemove(file, fileList) {

			},
			handleRemove2(file, fileList) {
				this.ruleForm.sharePhoto = fileList
			},
			handleSuccess(response, file, fileList) {
				this.$message.closeAll();
				if(response.code === 200) {
					this.$message({
						message: "上传成功",
						type: "success",
					});
				}else {
					this.$message({
						message: response.message ? response.message :"上传失败",
						type: "error",
					});
				}
				let arr = [];
				fileList = fileList.map((item, index) => {
					if (item.response.code === 200) {
						arr.push({
							url: `https://mzmpic.oss-cn-beijing.aliyuncs.com/${item.response.data}`,
							uid: item.uid,
						});
					} else {
						fileList.splice(index, 1)
						arr.splice(index, 1)
					}
				});
				this.ruleForm.productsPhoto = arr;
			},
			handleSuccess2(response, file, fileList) {
				this.$message.closeAll();
				if(response.code === 200) {
					this.$message({
						message: "上传成功",
						type: "success",
					});
				}else {
					this.$message({
						message: response.message ? response.message :"上传失败",
						type: "error",
					});
				}
				let arr = [];
				fileList = fileList.map((item, index) => {
					if (item.response.code === 200) {
						arr.push({
							url: `https://mzmpic.oss-cn-beijing.aliyuncs.com/${item.response.data}`,
							uid: item.uid,
						});
					} else {
						fileList.splice(index, 1)
						arr.splice(index, 1)
						// arr.push(item);
					}
				});
				this.ruleForm.sharePhoto = arr;
				console.log(this.ruleForm.sharePhoto)
			},
			async getProductsInfo() {
				const result = await getProductsInfo({
					productsId: this.$route.query.id
				}, {
					showLoading: true
				});
				const {
					productsTypeId,
					productsName,
					describe,
					productsPhoto,
					sharePhoto,
					serviceType,
					productsDetails,
				} = result.data;
				this.ruleForm.productsTypeId = productsTypeId;
				this.ruleForm.productsName = productsName;
				if (productsPhoto.split(",") && productsPhoto.split(",").length !== 0) {
					let arr = [];
					productsPhoto.split(",").map((item, index) => {
						arr.push({
							url: item,
							uid: index
						});
					});
					this.ruleForm.productsPhoto = arr;
				}
				this.ruleForm.describe = describe;
				this.ruleForm.sharePhoto = [{
					url: sharePhoto,
					uid: 1
				}];
				this.ruleForm.productsDetails = productsDetails;
				this.ruleForm.serviceType =
					serviceType == 3 ? ["1", "2"] : [String(serviceType)];
				let price;
				try {
					price = JSON.parse(result.data.price);
				} catch (e) {
					price = parseInt(result.data.price) || 0;
				}
				if (
					Object.prototype.toString.call(price).toLowerCase() ===
					"[object number]"
				) {
					this.radio = "1";
					this.ruleForm.specificPrice = result.data.price;
				} else {
					this.radio = "2";
					this.ruleForm.areaPrice = price;
				}
			},
			async selectProductsTypeList() {
				const result = await selectProductsTypeList({
					ignore: true
				});
				this.options = result.data;
			},
			addGoodsInfo() {
				if (this.ruleForm.productsPhoto.length == 0) {
					return this.$message({
						message: "商品图片未上传",
						type: "warning",
						center: true,
					});
				}
				if (this.ruleForm.sharePhoto.length == 0) {
					return this.$message({
						message: "商品分享图片未上传",
						type: "warning",
						center: true,
					});
				}
				this.$refs.ruleForm.validate(async (valid) => {
					if (valid) {
						console.log(this.ruleForm.sharePhoto)
						let data = {
							...this.ruleForm,
							price: this.radio == 1 ?
								this.ruleForm.specificPrice :
								JSON.stringify(this.ruleForm.areaPrice),
							serviceType: this.ruleForm.serviceType.reduce(
								(sum, val) => parseInt(sum) + parseInt(val)
							),
							productsPhoto: this.ruleForm.productsPhoto
								.map((item) => item.url.replace(".primary.png", ""))
								.join(","),
							sharePhoto: this.ruleForm.sharePhoto[0].url || '',
						};
						if (this.$route.query.id) data.productsId = this.$route.query.id;
						let result = await insertProducts(data, {
							showLoading: true
						});
						if (result.code == 201) {
							this.$message({
								message: result.message,
								type: "warning",
								center: true,
							});
						} else {
							setTimeout(() => {
								this.$router.go(-1);
							}, 1000);
						}

					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			//新增
			addGoodsType() {
				this.$prompt("新增分类", "提示", {
						onConfirmButtonText: "确定",
						cancelButtonText: "取消",
					})
					.then(({
						value
					}) => {
						let data = {
							productsTypeName: value,
						};
						addAppointmentGoodsType(data)
							.then(res => {
								this.$message({
									type: "success",
									message: res.message,
								});
								this.searchKey = ''
								this.selectProductsTypeList();
							});

					})
					.catch((err) => {
						console.log(err);
						this.$message({
							type: "info",
							message: "取消输入",
						});
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	/deep/ input::-webkit-outer-spin-button,
	/deep/ input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
	}

	/deep/ input[type="number"] {
		-moz-appearance: textfield !important;
	}

	/deep/ .disabled .el-upload--picture-card {
		display: none;
	}
</style>
